import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceForAllService } from '../service-for-all.service';
import { Events } from '../events';
import { AlertController, ModalController, LoadingController, NavParams, NavController, ActionSheetController, MenuController, ToastController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-capture-photo',
  templateUrl: './capture-photo.page.html',
  styleUrls: ['./capture-photo.page.scss'],
})
@Component({
  selector: 'app-capture-photo',
  templateUrl: './capture-photo.page.html',
  styleUrls: ['./capture-photo.page.scss'],
})
export class CapturePhotoPage implements OnInit {

  user:any=[];
  upload_type: any = '';
  loading: any;
  formrespnse: any;

  stream: any;
  video: any;
  canvas: any;
  is_webcam_ready: boolean = false;
  image_src:any ='';
  width:any=320;
  height:any=0;
  preview_image_url: any;
  type:string;
  uid:number;
  uid_token:any;

  constructor(
    public events: Events,
    public allServicesService: ServiceForAllService,
    public loadingCtrl: LoadingController,
    public router: Router,
    public alertCtrl: AlertController,
    public modalController: ModalController,
    private navParams: NavParams,
    public storage: Storage,
    private ref: ChangeDetectorRef,
    private toastController: ToastController,
    // public menu: MenuController,
    // private plt: Platform,
    // private loadingController: LoadingController,
    // private actionSheetController: ActionSheetController,
    // private ref: ChangeDetectorRef,
    // private camera: Camera,
    // private file: File,
    // public transfer: FileTransfer,
    // private filePath: FilePath
  ) {
    this.storage.get('user').then(userInfo => {
      if (userInfo != null) {
        this.user = userInfo;
      } else {
        this.router.navigate(['/login']);
      }
    }, err => {
      this.router.navigate(['/login']);
    });
    
    this.upload_type = this.navParams.get('upload_type');
    this.type = this.navParams.get('type');
    this.uid = this.navParams.get('uid');
    this.uid_token = this.navParams.get('uid_token');
    console.log("uid : ",this.uid);
    console.log("uid_token: ",this.uid_token);
    
    if(this.upload_type){
      this.start_stream();
    }
   }

  ngOnInit() {
  }

  async start_stream(){
    this.showLoader();
    const constraints = {
      video: {
        width: { ideal: innerWidth },
        height: { ideal: innerHeight },
        facingMode: "environment"
      },
      audio: true
    };
    try {
      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.handleSuccess(this.stream);
      this.dismissLoading();
    } catch (e) {
      this.dismissLoading();
      console.error('navigator.getUserMedia error:', e);
    }
    
  }

  handleSuccess(stream) {
    this.dismissLoading();
    this.video = document.querySelector('#video');
    this.video.srcObject = stream;
    this.ref.detectChanges();
    this.is_webcam_ready = true;
    console.log("this.video.srcObject: ", this.video.srcObject);
    
  }

  take_photo(upload_type) {
    this.canvas = document.getElementById('canvas');
    this.height = this.video.videoHeight / (this.video.videoWidth / this.width);
    if (isNaN(this.height)) {
      this.height = this.width / (4 / 3);
    }
    console.log(this.width);
    console.log(this.height);
    this.upload_type = upload_type;
    this.is_webcam_ready = false;
    
    var photo = document.getElementById('photo');
    var context = this.canvas.getContext('2d');
    context.drawImage(this.video, 0, 0, this.width,this.height);
    var data = this.canvas.toDataURL('image/jpeg');
    this.image_src = data;

      // photo.setAttribute('src', data);
    
      this.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    
    // this.uploadAll(data, 'Camera');
  }

  retake_picture(upload_type){
    this.upload_type = upload_type;
    this.image_src = '';
    this.start_stream();
  }
  // clearRecording() {
  //   this.is_webcam_ready = false;
  //   this.image_src = '';
  //   console.log(this.video);
  //   this.video.src = this.video.srcObject = null;
  //   this.stream.getTracks().forEach(function (track) {
  //     track.stop();
  //   });
  // }
  
  async uploadAll(webPath: string, type: string) {
    console.log("type: " + type);
    this.loading = await this.loadingCtrl.create({
      message: 'Uploading...'
    });
    await this.loading.present();
    let date = new Date(),
      time = date.getTime(),
      fileName = time + ".jpg";
    const blob = '';

    const formData = new FormData();
    if (type == 'Camera') {
      const blob = await fetch(webPath).then(r => r.blob());
      formData.append('file', blob, fileName);

    } else if (type == 'Record') {
      const blob = await fetch(webPath).then(r => r.blob());
      formData.append('file', blob, time + '.webm');

    } else {
      const blob = webPath;
      formData.append('file', blob);
      console.log('formData', formData);
    }

    formData.append('upload_type', this.upload_type);
    let token: any
    if (this.type == 'login_user') {
      token = this.user.token;
    } else {
      token = this.uid_token;
    }
    console.log(this.user.token); 
    formData.append('token',token);
    if (this.user) {
      this.allServicesService.upload_image(formData)
        .subscribe(res => {
          let d:any = [];
          d = res;
          if(d.upload_type=='user_profile'){
            if (this.type == 'login_user') {
              this.preview_image_url = d.file_path;
              this.user.user_avatar = d.file_path;
              let newUrl = d.file_path;
              this.storage.get('user').then((data)=>{
                data.user_avatar = newUrl;
                this.storage.set('user', data);
                this.events.publish('user:profile_updated2', data); 
              });
              this.closeModal();
            } else {
              this.preview_image_url = d.file_path;
              this.user.user_avatar = d.file_path;
              let newUrl = d.file_path;
              this.modalController.dismiss({preview_image_url:this.preview_image_url,user_avatar:this.user.user_avatar,newUrl:newUrl});
            }

          }else if(d.upload_type=='post_image' || d.upload_type=='comment_image' || d.upload_type =='group_profile'){
            this.modalController.dismiss(d);
          }
          this.loading.dismiss();
          this.ref.detectChanges();
        }, (err) => {
          console.log(err);
          if (err.error_code == 'user_expire') {
            this.presentToast('Please login again token expired.');
            this.storage.remove('user');
            this.router.navigate(['/signin']);
          }
          this.loading.dismiss();
          this.closeModal();
        });
    } else {
      this.loading.dismiss();
      this.closeModal();
      this.router.navigate(['/signin']);
    }
  }

  async closeModal() {
    this.is_webcam_ready = false;
    this.image_src = '';
    console.log(this.video);
    this.video.src = this.video.srcObject = null;
    this.stream.getTracks().forEach(function (track) {
      track.stop();
    });
    await this.modalController.dismiss();
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000
    });
    toast.present();
  }
  async showLoader(msg: string = '') {
    if (msg == '') {
      msg = 'Please wait...';
    }
    this.loading = await this.loadingCtrl.create({ message: msg });
    await this.loading.present();
  }

  async dismissLoading() {
    console.log(this.loading);
    await this.loading.dismiss();
  }

}
