import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(
    public storage: Storage,
    public router: Router
  ) {

  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    return this.storage.get('user').then(res => {
      console.log("AuthLog", res);
      const navigation = this.router.getCurrentNavigation();
      let url = '/';
      if (navigation) {
        url = navigation.extractedUrl.toString();
        console.log("url", url)
        if (url == '/login' || url == '/tabs/login') {
          if (res != null) {
            this.router.navigate(['/tabs/dashboard']);
          } else {
            return true;
          }
        } else {
          if (res != null) {
            return true;
          } else {
            this.router.navigate(['/login']);
          }
        }
      }else{
        this.storage.remove('user');
        this.router.navigate(['/login']);
      }
    });
  }
}
