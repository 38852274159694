import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ViewChild, ElementRef, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';
import { Router } from '@angular/router';
import { ToastController, ModalController, NavController, NavParams, MenuController, LoadingController, AlertController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.page.html',
  styleUrls: ['./search-filter.page.scss'],
})
export class SearchFilterPage implements OnInit {

  @ViewChild('map', { static: false }) mapElement: ElementRef;
  @ViewChild('content', { static: false }) content: IonContent;

  mapView: boolean = false;

  user: any;
  loading: any;
  filter_type: any;
  current_filters: any = '';
  loading_spinner: boolean;
  starBox: any = [];
  rate: number = 0;
  isSavedInput: boolean = false;

  AE_functional_areas: any = '';
  AE_highest_t_ac: any = '';
  AE_largest_pl: any = '';
  private_equity: any = '';
  business_experiencing: any = '';
  turn_around_restructuring: any = '';
  OI_ftc: any = '';
  OI_ftf: any = '';
  OI_ptf: any = '';
  isReady: boolean = false;
  selectedRole: string = '';
  fund_size_min: number;
  fund_size_max: number;
  control_orientation: any;
  savedSearch: string = '';
  saved_executive: any = [];
  saved_sponsor: any = [];
  isSaveFolder: boolean = false;
  folders: any = [];
  folder_id: number = 0;
  isNaicsLoader: boolean = false;
  naics: any = [];
  selectedNaicsIds: any = [];
  check_mobile_screen = window.navigator.userAgent.toLowerCase().includes("mobile");
  isPlatformAndroidIos: boolean = false;
  business_strategy: any = '';
  research_development: any = '';
  operations: any = '';
  marketing_sales: any = '';
  support_administration: any = '';

  constructor(
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    public router: Router,
    private navParams: NavParams,
    public loadingCtrl: LoadingController,
    public modalController: ModalController,
    public alertCtrl: AlertController,
    public menu: MenuController,
    public navCtrl: NavController,
    public toastController: ToastController,
    private geolocation: Geolocation,
    private zone: NgZone,
    private cdref: ChangeDetectorRef,
  ) {
    this.starBox = [1, 2, 3, 4, 5];
    this.rate = 0;

    this.isPlatformAndroidIos = this.serviceForAllService.isPlatformAndroidIos;


    setTimeout(() => {
      this.filter_type = this.navParams.get('filter_type');
      this.selectedRole = this.navParams.get('selectedRole');
    }, 200);

    this.storage.get('user').then((user) => {
      if (user) {
        this.user = user;
        this.isReady = true;
        console.log("user=", this.user)
        this.get_folder();
      }
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.getSavedSearch();
    this.getNaics();

  }

  selectRole(ev: any) {
    console.log("selectRole=", ev.target.value)
    this.selectedRole = ev.target.value;
    this.selectedNaicsIds = [];
    this.getNaics();
  }
  async clearFilter() {
    await this.modalController.dismiss({
      filter_type: this.filter_type,
      selectedRole: '',
      AE_functional_areas: '',
      AE_highest_t_ac: '',
      AE_largest_pl: '',
      business_experiencing: '',
      turn_around_restructuring: '',
      OI_ftc: '',
      OI_ftf: '',
      OI_ptf: '',
      action: 'ok',
      selectedNaicsIds: [],
      fund_size_min: 0,
      fund_size_max: 0,
      control_orientation: '',
      business_strategy: '',
      research_development: '',
      operations: '',
      marketing_sales: '',
      support_administration: '',
    });
  }

  async closeModal(action) {
    if (action == 'ok') {

      await this.modalController.dismiss({
        filter_type: this.filter_type,
        selectedRole: this.selectedRole,
        AE_highest_t_ac: this.AE_highest_t_ac,
        AE_largest_pl: this.AE_largest_pl,
        business_experiencing: this.business_experiencing,
        turn_around_restructuring: this.turn_around_restructuring,
        OI_ftc: this.OI_ftc,
        OI_ftf: this.OI_ftf,
        OI_ptf: this.OI_ptf,
        action: action,
        selectedNaicsIds: this.selectedNaicsIds,
        fund_size_min: this.fund_size_min,
        fund_size_max: this.fund_size_max,
        control_orientation: this.control_orientation,
        business_strategy: this.business_strategy,
        research_development: this.research_development,
        operations: this.operations,
        marketing_sales: this.marketing_sales,
        support_administration: this.support_administration,
      });

    } else {
      await this.modalController.dismiss({
        filter_type: this.filter_type,
        selectedRole: this.selectedRole,
        AE_functional_areas: this.AE_functional_areas,
        AE_highest_t_ac: this.AE_highest_t_ac,
        AE_largest_pl: this.AE_largest_pl,
        business_experiencing: this.business_experiencing,
        turn_around_restructuring: this.turn_around_restructuring,
        OI_ftc: this.OI_ftc,
        OI_ftf: this.OI_ftf,
        OI_ptf: this.OI_ptf,
        action: action,
        selectedNaicsIds: this.selectedNaicsIds,
        fund_size_min: this.fund_size_min,
        fund_size_max: this.fund_size_max,
        control_orientation: this.control_orientation,
        business_strategy: this.business_strategy,
        research_development: this.research_development,
        operations: this.operations,
        marketing_sales: this.marketing_sales,
        support_administration: this.support_administration,
      });
    }


  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });

    toast.present();
  }

  change_filter_type(event) {
    //this.clear_user_filter();
    this.filter_type = event.detail.value;
  }
  checkedPrivate(ev: any) {
    const isChecked = ev.currentTarget.checked;
    if (isChecked == true) {
      this.private_equity = 'Yes';
    } else {
      this.private_equity = '';
    }
    console.log(" this.private_equity : ", this.private_equity);
  }

  checkedHyper(ev: any) {
    const isChecked = ev.currentTarget.checked;
    if (isChecked == true) {
      this.business_experiencing = 'Yes';
    } else {
      this.business_experiencing = '';
    }
    console.log(" this.business_experiencing : ", this.business_experiencing);
  }

  checkedTurnaround(ev: any) {
    const isChecked = ev.currentTarget.checked;
    if (isChecked == true) {
      this.turn_around_restructuring = 'Yes';
    } else {
      this.turn_around_restructuring = '';
    }
    console.log("this.turn_around_restructuring : ", this.turn_around_restructuring);

  }

  savedChekbox(ev: any) {
    setTimeout(() => {
      const isChecked = ev.target.checked;
      if (isChecked == true) {
        this.isSavedInput = true;
        // this.scrollToBottomFunction();
      } else {
        this.isSavedInput = false;
      }
    }, 100);
  }

  save_search() {

    if (this.savedSearch == '') {
      this.presentToast("Please enter search template name.");
      return false;
    }
    this.showLoader("Please wait...")
    this.storage.get('user').then((user) => {
      if (user) {
        let sendData: any = {
          saved_search_name: this.savedSearch,
          selectedRole: this.selectedRole,
          token: user.token,
          selectedNaicsIds: this.selectedNaicsIds,
          highest_title: this.AE_highest_t_ac,
          pl_oversite: this.AE_largest_pl,
          hyper_growth: this.business_experiencing,
          turnaround: this.turn_around_restructuring,
          OI_ftc: this.OI_ftc,
          OI_ftf: this.OI_ftf,
          OI_ptf: this.OI_ptf,
          fund_size_min: this.fund_size_min,
          fund_size_max: this.fund_size_max,
          control_orientation: this.control_orientation,
          folder_id: this.folder_id,
          business_strategy: this.business_strategy,
          research_development: this.research_development,
          operations: this.operations,
          marketing_sales: this.marketing_sales,
          support_administration: this.support_administration,

        }
        console.log("sendData :", sendData);
        this.serviceForAllService.save_search(sendData).subscribe(res => {
          this.loading.dismiss();
          let result: any = [];
          result = res;
          this.presentToast(result.msg);
          this.getSavedSearch();

        }, (err) => {
          this.loading.dismiss();
          console.log(err);
          let msg = err.error.errormsg;
          this.presentToast(msg);
          if (err.error.error_code == 'user_expire') {
            this.router.navigate(['/login']);
            this.storage.remove('user');
          }
        });
      } else {
        this.router.navigate(['/login']);
      }
    });

  }

  getSavedSearch() {
    this.storage.get('user').then((user) => {
      if (user) {
        this.serviceForAllService.get_saved_search(user.token).subscribe((result) => {
          let res: any = [];
          res = result;
          console.log("res :", res);
          this.saved_executive = res.saved_executive;
          this.saved_sponsor = res.saved_sponsor;

        }, (err) => {
          console.log("error...", err);
          let msg = err.error.errormsg;
          this.presentToast(msg);
          if (err.error.errorcode == 'token_expired') {
            this.router.navigate(['/login']);
            this.storage.remove('user');
          }
        });
      }
    });
  }
  setPrefrencesExe(ev: any) {

    let ind = ev.target.value;
    let row = this.saved_executive[ind];
    let saved_search = row.search_param;
    this.selectedNaicsIds = saved_search.naics_ids,
      this.AE_functional_areas = saved_search.functional_areas;
    this.AE_highest_t_ac = saved_search.highest_title;
    this.AE_largest_pl = saved_search.pl_oversite;
    this.business_experiencing = saved_search.hyper_growth;
    this.turn_around_restructuring = saved_search.turnaround;
    this.OI_ftc = saved_search.OI_ftc;
    this.OI_ftf = saved_search.OI_ftf;
    this.OI_ptf = saved_search.OI_ptf;
    this.business_strategy = saved_search.business_strategy;
    this.research_development = saved_search.research_development;
    this.operations = saved_search.operations,
    this.marketing_sales = saved_search.marketing_sales;
    this.support_administration = saved_search.support_administration;

    let a = 0;
    this.naics.forEach(c => {
      if (this.selectedNaicsIds.indexOf(c.cat_ID) > -1) {
        this.naics[a].isChecked = true;
      } else {
        this.naics[a].isChecked = false;
      }
      let b = 0;
      c.child.forEach(c1 => {
        if (this.selectedNaicsIds.indexOf(c1.cat_ID) > -1) {
          this.naics[a].child[b].isChecked = true;
        } else {
          this.naics[a].child[b].isChecked = false;
        }
        let c = 0;
        c1.sub_child.forEach(c2 => {
          if (this.selectedNaicsIds.indexOf(c2.cat_ID) > -1) {
            this.naics[a].child[b].sub_child[c].isChecked = true;
          } else {
            this.naics[a].child[b].sub_child[c].isChecked = false;
          }
          let d = 0;
          c2.sub_sub_child.forEach(c3 => {
            if (this.selectedNaicsIds.indexOf(c3.cat_ID) > -1) {
              this.naics[a].child[b].sub_child[c].sub_sub_child[d].isChecked = true;
            } else {
              this.naics[a].child[b].sub_child[c].sub_sub_child[d].isChecked = false;
            }
            let e = 0;
            c3.sub_sub_sub_child.forEach(c4 => {
              if (this.selectedNaicsIds.indexOf(c4.cat_ID) > -1) {
                this.naics[a].child[b].sub_child[c].sub_sub_child[d].sub_sub_sub_child.isChecked = true;
              } else {
                this.naics[a].child[b].sub_child[c].sub_sub_child[d].sub_sub_sub_child.isChecked = false;
              }
              e++;
            });
            d++;
          });
          c++;
        });
        b++;
      });
      a++;
    });
    // this.closeModal('ok');

  }

  setPrefrencesSponsor(ev: any) {
    let ind = ev.target.value;
    let row = this.saved_sponsor[ind];
    let saved_search = row.search_param;
    this.selectedNaicsIds = saved_search.naics_ids;
    this.fund_size_min = saved_search.fund_size_min;
    this.fund_size_max = saved_search.fund_size_max;
    this.control_orientation = saved_search.control_orientation;

    let a = 0;
    this.naics.forEach(c => {
      if (this.selectedNaicsIds.indexOf(c.cat_ID) > -1) {
        this.naics[a].isChecked = true;
      } else {
        this.naics[a].isChecked = false;
      }
      let b = 0;
      c.child.forEach(c1 => {
        if (this.selectedNaicsIds.indexOf(c1.cat_ID) > -1) {
          this.naics[a].child[b].isChecked = true;
        } else {
          this.naics[a].child[b].isChecked = false;
        }
        let c = 0;
        c1.sub_child.forEach(c2 => {
          if (this.selectedNaicsIds.indexOf(c2.cat_ID) > -1) {
            this.naics[a].child[b].sub_child[c].isChecked = true;
          } else {
            this.naics[a].child[b].sub_child[c].isChecked = false;
          }
          let d = 0;
          c2.sub_sub_child.forEach(c3 => {
            if (this.selectedNaicsIds.indexOf(c3.cat_ID) > -1) {
              this.naics[a].child[b].sub_child[c].sub_sub_child[d].isChecked = true;
            } else {
              this.naics[a].child[b].sub_child[c].sub_sub_child[d].isChecked = false;
            }
            let e = 0;
            c3.sub_sub_sub_child.forEach(c4 => {
              if (this.selectedNaicsIds.indexOf(c4.cat_ID) > -1) {
                this.naics[a].child[b].sub_child[c].sub_sub_child[d].sub_sub_sub_child.isChecked = true;
              } else {
                this.naics[a].child[b].sub_child[c].sub_sub_child[d].sub_sub_sub_child.isChecked = false;
              }
              e++;
            });
            d++;
          });
          c++;
        });
        b++;
      });
      a++;
    });
    // this.closeModal('ok');
  }

  scrollToBottomFunction() {
    setTimeout(() => {
      this.content.scrollToBottom(100);
    }, 400);
  }

  async folderCheckbox(ev: any) {
    const isChecked = ev.currentTarget.checked;
    if (isChecked) {
      this.isSaveFolder = true;
    } else {
      this.isSaveFolder = true;
    }
  }

  get_folder() {
    let sendData: any = {
      token: this.user.token,
    }
    console.log("sendData :", sendData);
    this.serviceForAllService.get_folders(sendData).subscribe(res => {
      this.isReady = true;
      let result: any = [];
      result = res;
      this.folders = result.folder;

    }, (err) => {
      console.log(err);
      let msg = err.error.errormsg;
      this.presentToast(msg);
      if (err.error.error_code == 'token_expired') {
        this.router.navigate(['/login']);
        this.storage.remove('user');
      }
    });
  }
  selectFolder(ev: any) {
    console.log("folder id : ", ev.target.value);
    this.folder_id = ev.target.value;
  }

  getNaics() {
    this.storage.get('user').then((user) => {
      if (user) {
        let sendData: any = {
          token: user.token,
          isFilterPage: 'yes',
        }
        this.isNaicsLoader = true;
        this.serviceForAllService.get_naics(sendData).subscribe((result) => {
          this.isNaicsLoader = false;
          let res: any = [];
          res = result;
          this.naics = res.naics_category;
        }, (err) => {
          this.isNaicsLoader = false;
          console.log("error...", err);
          let msg = err.error.errormsg;
          this.presentToast(msg);
          if (err.error.error_code == 'user_expire') {
            this.router.navigate(['/login']);
            this.storage.remove('user');
          }
        });
      }
    });
  }

  selectNaicsCat(ev: any, cat_id) {

    console.log("cat_id :", cat_id);
    let i = 0;
    let indx;
    let checked = ev.target.checked;
    if (checked) {
      this.selectedNaicsIds.push(cat_id);
    } else {
      indx = this.selectedNaicsIds.indexOf(cat_id);
      if (indx > -1) {
        this.selectedNaicsIds.splice(indx, 1);
      }

    }
    console.log("selectedNaicsIds :", this.selectedNaicsIds);
  }

  selectNaicsCat_0(ev: any, cat_id, level, index) {
    let i = 0;
    let indx;
    let checked = ev.target.checked;


    if (checked) {
      this.selectedNaicsIds.push(cat_id);
    } else {
      indx = this.selectedNaicsIds.indexOf(cat_id);
      if (indx > -1) {
        this.selectedNaicsIds.splice(indx, 1);
      }

      this.naics[index].child.forEach(s => {

        let indx
        indx = this.selectedNaicsIds.indexOf(s.cat_ID);
        if (indx > -1) {
          this.selectedNaicsIds.splice(indx, 1);
        }
        s.sub_child.forEach(s1 => {

          let ind
          ind = this.selectedNaicsIds.indexOf(s1.cat_ID);
          if (ind > -1) {
            this.selectedNaicsIds.splice(ind, 1);
          }

          s1.sub_sub_child.forEach(s2 => {
            let index2
            index2 = this.selectedNaicsIds.indexOf(s2.cat_ID);
            if (index2 > -1) {
              this.selectedNaicsIds.splice(index2, 1);
            }
            s2.sub_sub_sub_child.forEach(s3 => {
              let index3
              index3 = this.selectedNaicsIds.indexOf(s3.cat_ID);
              if (index3 > -1) {
                this.selectedNaicsIds.splice(index3, 1);
              }
            });
          });
        });
      });
    }
  }

  selectNaicsCat_1(ev: any, cat_id, index_0, index_1) {

    let indx;
    let checked = ev.target.checked;
    if (checked) {
      this.selectedNaicsIds.push(cat_id);
    } else {
      indx = this.selectedNaicsIds.indexOf(cat_id);
      if (indx > -1) {
        this.selectedNaicsIds.splice(indx, 1);
      }
      this.naics[index_0].child[index_1].sub_child.forEach(s1 => {
        let indx1
        indx1 = this.selectedNaicsIds.indexOf(s1.cat_ID);
        if (indx1 > -1) {
          this.selectedNaicsIds.splice(indx1, 1);
        }
        s1.sub_sub_child.forEach(s2 => {
          let indx2
          indx2 = this.selectedNaicsIds.indexOf(s2.cat_ID);
          if (indx2 > -1) {
            this.selectedNaicsIds.splice(indx2, 1);
          }
          s2.sub_sub_sub_child.forEach(s3 => {
            let indx3
            indx3 = this.selectedNaicsIds.indexOf(s3.cat_ID);
            if (indx3 > -1) {
              this.selectedNaicsIds.splice(indx3, 1);
            }
          });
        });
      });
    }
  }

  selectNaicsCat_2(ev: any, cat_id, index_0, index_1, index_2) {

    let indx;
    let checked = ev.target.checked;
    if (checked) {
      this.selectedNaicsIds.push(cat_id);
    } else {
      indx = this.selectedNaicsIds.indexOf(cat_id);
      if (indx > -1) {
        this.selectedNaicsIds.splice(indx, 1);
      }

      this.naics[index_0].child[index_1].sub_child[index_2].sub_sub_child.forEach(s1 => {
        let indx1
        indx1 = this.selectedNaicsIds.indexOf(s1.cat_ID);
        if (indx1 > -1) {
          this.selectedNaicsIds.splice(indx1, 1);
        }
        s1.sub_sub_sub_child.forEach(s2 => {
          let indx2
          indx2 = this.selectedNaicsIds.indexOf(s2.cat_ID);
          if (indx2 > -1) {
            this.selectedNaicsIds.splice(indx2, 1);
          }
        });
      });
    }
  }
  selectNaicsCat_3(ev: any, cat_id, index_0, index_1, index_2, index_3) {

    let indx;
    let checked = ev.target.checked;
    if (checked) {
      this.selectedNaicsIds.push(cat_id);


    } else {
      indx = this.selectedNaicsIds.indexOf(cat_id);
      if (indx > -1) {
        this.selectedNaicsIds.splice(indx, 1);
      }

      this.naics[index_0].child[index_1].sub_child[index_2].sub_sub_child[index_3].sub_sub_sub_child.forEach(s1 => {
        let indx1
        indx1 = this.selectedNaicsIds.indexOf(s1.cat_ID);
        if (indx1 > -1) {
          this.selectedNaicsIds.splice(indx1, 1);
        }
      });
    }
  }
  async showLoader(msg) {
    this.loading = await this.loadingCtrl.create({ message: msg, backdropDismiss: false });
    this.loading.present();
  }
}
