import { Component, OnInit } from '@angular/core';
import { ServiceForAllService } from '../service-for-all.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ModalController, MenuController, LoadingController, AlertController, ToastController } from '@ionic/angular';
import { AddProfessionalUsersPage } from '../add-professional-users/add-professional-users.page';

@Component({
  selector: 'app-professionals-popover',
  templateUrl: './professionals-popover.page.html',
  styleUrls: ['./professionals-popover.page.scss'],
})
export class ProfessionalsPopoverPage implements OnInit {
  user: any = [];
  user_id: any = null;
  selected_users: any = [];

  constructor(
    public serviceForAllService: ServiceForAllService,
    private router: Router,
    public route: ActivatedRoute,
    public popoverController: PopoverController,
    public storage: Storage,
    public modalController: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public toastController: ToastController
  ) {
    this.storage.get('user').then((val) => {
      if (val != null) {
        this.user = val;
      }
    });
  }

  ngOnInit() {
  }

  async addProfessionalUsers() {

    const modal = await this.modalController.create({
      component: AddProfessionalUsersPage,
      // componentProps: { selected_users: this.selected_users},
      // cssClass: 'capture_photo_modal'
    });

    modal.onDidDismiss().then((res) => {
      this.dismissPopover();
      console.log("modal_response: " + JSON.stringify(res));
      let model_response: any = [];
      model_response = res.data;
      // if (model_response.action == 'ok') {
      //   this.getGroupUsers(this.group_id);
      // }
    });
    return await modal.present();
  }

  async dismissPopover() {
    await this.popoverController.dismiss({user_id: this.user_id});
  }
  getProfessional(){
      let temp:any = [];
  
      temp['type'] = 'my_professionals';
      let navigationExtras: NavigationExtras = {
        queryParams: temp
      };
      this.router.navigate(['/tabs/professional-list'], navigationExtras);
      this.popoverController.dismiss({user_id: this.user_id});
      
    
  }

  getPendingProfessional(){
    let temp:any = [];

    temp['type'] = 'pending_professional';
    let navigationExtras: NavigationExtras = {
      queryParams: temp
    };
    this.router.navigate(['/tabs/professional-list'], navigationExtras);
    this.popoverController.dismiss({user_id: this.user_id});
  }

}
