import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule),
  },
  {
    path: 'forgot',
    loadChildren: () => import('./forgot/forgot.module').then( m => m.ForgotPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then( m => m.EditProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'edit-profile/:uid',
    loadChildren: () => import('./edit-profile/edit-profile.module').then( m => m.EditProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'redo',
    loadChildren: () => import('./redo/redo.module').then( m => m.RedoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'redo/:folder_id',
    loadChildren: () => import('./redo/redo.module').then( m => m.RedoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then( m => m.MessagesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'privacy-statement',
    loadChildren: () => import('./privacy-statement/privacy-statement.module').then( m => m.PrivacyStatementPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'contactus',
    loadChildren: () => import('./contactus/contactus.module').then( m => m.ContactusPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'search/:selected_role',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-edit-post',
    loadChildren: () => import('./add-edit-post/add-edit-post.module').then( m => m.AddEditPostPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'actions',
    loadChildren: () => import('./actions/actions.module').then( m => m.ActionsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'editcomment',
    loadChildren: () => import('./editcomment/editcomment.module').then( m => m.EditcommentPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'replycomment',
    loadChildren: () => import('./replycomment/replycomment.module').then( m => m.ReplycommentPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'followers',
    loadChildren: () => import('./followers/followers.module').then( m => m.FollowersPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'following',
    loadChildren: () => import('./following/following.module').then( m => m.FollowingPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'otheruser-profile',
    loadChildren: () => import('./otheruser-profile/otheruser-profile.module').then( m => m.OtheruserProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./changepassword/changepassword.module').then( m => m.ChangepasswordPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./portfolio/portfolio.module').then( m => m.PortfolioPageModule),
  },
  {
    path: 'popover-searchoptions',
    loadChildren: () => import('./popover-searchoptions/popover-searchoptions.module').then( m => m.PopoverSearchoptionsPageModule)
  },
  {
    path: 'report-as-abuse',
    loadChildren: () => import('./report-as-abuse/report-as-abuse.module').then( m => m.ReportAsAbusePageModule)
  },
  {
    path: 'portfolio-single',
    loadChildren: () => import('./portfolio-single/portfolio-single.module').then( m => m.PortfolioSinglePageModule)
  },
  {
    path: 'likes-list',
    loadChildren: () => import('./likes-list/likes-list.module').then( m => m.LikesListPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'invite-friend',
    loadChildren: () => import('./invite-friend/invite-friend.module').then( m => m.InviteFriendPageModule)
  },
  {
    path: 'video-popup',
    loadChildren: () => import('./video-popup/video-popup.module').then( m => m.VideoPopupPageModule)
  },
  {
    path: 'tag-user-popup',
    loadChildren: () => import('./tag-user-popup/tag-user-popup.module').then( m => m.TagUserPopupPageModule)
  },
  {
    path: 'search-filter',
    loadChildren: () => import('./search-filter/search-filter.module').then( m => m.SearchFilterPageModule)
  },
  {
    path: 'activity-single',
    loadChildren: () => import('./activity-single/activity-single.module').then( m => m.ActivitySinglePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'forums',
    loadChildren: () => import('./forums/forums.module').then( m => m.ForumsPageModule)
  },
  {
    path: 'add-edit-forum',
    loadChildren: () => import('./add-edit-forum/add-edit-forum.module').then( m => m.AddEditForumPageModule)
  },
  {
    path: 'registertype',
    loadChildren: () => import('./registertype/registertype.module').then( m => m.RegistertypePageModule)
  },
  {
    path: 'communications',
    loadChildren: () => import('./communications/communications.module').then( m => m.CommunicationsPageModule)
  },
  {
    path: 'professionals',
    loadChildren: () => import('./professionals/professionals.module').then( m => m.ProfessionalsPageModule)
  },
  {
    path: 'executive-edit-profile',
    loadChildren: () => import('./executive-edit-profile/executive-edit-profile.module').then( m => m.ExecutiveEditProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'capture-photo',
    loadChildren: () => import('./capture-photo/capture-photo.module').then( m => m.CapturePhotoPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'call',
    loadChildren: () => import('./call/call.module').then( m => m.CallPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'mypost',
    loadChildren: () => import('./mypost/mypost.module').then( m => m.MypostPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'request-list',
    loadChildren: () => import('./request-list/request-list.module').then( m => m.RequestListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'connections',
    loadChildren: () => import('./connections/connections.module').then( m => m.ConnectionsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'video-call',
    loadChildren: () => import('./video-call/video-call.module').then( m => m.VideoCallPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'audio-call',
    loadChildren: () => import('./audio-call/audio-call.module').then( m => m.AudioCallPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-edit-group',
    loadChildren: () => import('./add-edit-group/add-edit-group.module').then( m => m.AddEditGroupPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-edit-group/:uid',
    loadChildren: () => import('./add-edit-group/add-edit-group.module').then( m => m.AddEditGroupPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'group-details',
    loadChildren: () => import('./group-details/group-details.module').then( m => m.GroupDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-users-group',
    loadChildren: () => import('./add-users-group/add-users-group.module').then( m => m.AddUsersGroupPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-schedule-call',
    loadChildren: () => import('./add-schedule-call/add-schedule-call.module').then( m => m.AddScheduleCallPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'scheduled-calls',
    loadChildren: () => import('./scheduled-calls/scheduled-calls.module').then( m => m.ScheduledCallsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'scheduled-call-details',
    loadChildren: () => import('./scheduled-call-details/scheduled-call-details.module').then( m => m.ScheduledCallDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-reminders',
    loadChildren: () => import('./add-reminders/add-reminders.module').then( m => m.AddRemindersPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-reminders/:uid',
    loadChildren: () => import('./add-reminders/add-reminders.module').then( m => m.AddRemindersPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reminders',
    loadChildren: () => import('./reminders/reminders.module').then( m => m.RemindersPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reminder-details',
    loadChildren: () => import('./reminder-details/reminder-details.module').then( m => m.ReminderDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'saved-searches',
    loadChildren: () => import('./saved-searches/saved-searches.module').then( m => m.SavedSearchesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-edit-folder',
    loadChildren: () => import('./add-edit-folder/add-edit-folder.module').then( m => m.AddEditFolderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'save-to-folder',
    loadChildren: () => import('./save-to-folder/save-to-folder.module').then( m => m.SaveToFolderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'folder-details',
    loadChildren: () => import('./folder-details/folder-details.module').then( m => m.FolderDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'professional-pages',
    loadChildren: () => import('./professional-pages/professional-pages.module').then( m => m.ProfessionalPagesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-professional-page',
    loadChildren: () => import('./add-professional-page/add-professional-page.module').then( m => m.AddProfessionalPagePageModule),
  },
  {
    path: 'professional-page-details',
    loadChildren: () => import('./professional-page-details/professional-page-details.module').then( m => m.ProfessionalPageDetailsPageModule)
  },
  {
    path: 'add-professional-users',
    loadChildren: () => import('./add-professional-users/add-professional-users.module').then( m => m.AddProfessionalUsersPageModule)
  },
  {
    path: 'professionals-popover',
    loadChildren: () => import('./professionals-popover/professionals-popover.module').then( m => m.ProfessionalsPopoverPageModule)
  },
  {
    path: 'sponsors-list',
    loadChildren: () => import('./sponsors-list/sponsors-list.module').then( m => m.SponsorsListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'view-permission',
    loadChildren: () => import('./view-permission/view-permission.module').then( m => m.ViewPermissionPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'requested-calls',
    loadChildren: () => import('./requested-calls/requested-calls.module').then( m => m.RequestedCallsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'professional-list',
    loadChildren: () => import('./professional-list/professional-list.module').then( m => m.ProfessionalListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reminder-modal',
    loadChildren: () => import('./reminder-modal/reminder-modal.module').then( m => m.ReminderModalPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'share-folder',
    loadChildren: () => import('./share-folder/share-folder.module').then( m => m.ShareFolderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'call-feedback',
    loadChildren: () => import('./call-feedback/call-feedback.module').then( m => m.CallFeedbackPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'touch-setting',
    loadChildren: () => import('./touch-setting/touch-setting.module').then( m => m.TouchSettingPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'folder-details2',
    loadChildren: () => import('./folder-details2/folder-details2.module').then( m => m.FolderDetails2PageModule)
  }




];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
