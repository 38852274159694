import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { AlertController, LoadingController, NavController, MenuController, ToastController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ServiceForAllService {

  isLoading: boolean = false;
  url = 'https://ceodiscovery.betaplanets.com/wp-json/wp/v2/';
  wpUrl = 'https://ceodiscovery.betaplanets.com/';
  fullUrl = 'https://ceodiscovery.betaplanets.com/wp-json/mobileapi/v1/';
  totalPosts = null;
  pages: any;
  registerType: string = "";
  role: string = '';
  isPlatformAndroidIos: boolean;
  selectedRole: string = '';
  isUser : boolean =false;

  constructor(
    private http: HttpClient,
    public alertCtrl: AlertController,
    private plt: Platform,
    public loadingCtrl: LoadingController,
    public storage : Storage,
  ) {

    const isapp:any = (document.URL.startsWith('http://web.ceodiscovery.betaplanets.com')|| document.URL.startsWith('https://web.ceodiscovery.betaplanets.com') || document.URL.startsWith('http://localhost:8100'));
    if(isapp){
      console.log("PWA");
      this.isPlatformAndroidIos = false;
      console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
    }else{
      console.log("Device");
      this.isPlatformAndroidIos = true;
      console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
    }

    this.storage.get('user').then((user) => {
      if (user != null) {
        this.isUser = true;
      }
    });
  }

  update_profile(user_data) {
    console.log("Profile data === ", user_data);
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/update_profile', user_data)
  }

  doRegister(user_data) {
    console.log("SERVICES === ", user_data);
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/register', {
      email: user_data.email,
      password: user_data.password,
      username: user_data.email,
      first_name: user_data.first_name,
      last_name: user_data.last_name,
      artist_name: user_data.artist_name,
      dob: user_data.dob,
      gender: user_data.gender,
      user_interest: user_data.user_interest,
      jw_auth_sec: "wivxCNm$<(+WF#7}1]TWMUl7OaU*hghjdt574%$%^DFTY^&%FFTxS(r*$F8:akLdapjD[j.g0wgra#c/f,P`1l"
    })
  }

  doLogin(email, password) {
    console.log("SERVICE == " + email);
    return this.http.post(this.wpUrl + 'wp-json/jwt-auth/v1/token', {
      username: email,
      password: password,
      jw_auth_sec: "wivxCNm$<(+WF#7}1]TWMUl7OaU*hghjdt574%$%^DFTY^&%FFTxS(r*$F8:akLdapjD[j.g0wgra#c/f,P`1l"
    })
  }

  doReset(user_login) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/retrieve_password', {
      user_login: user_login
    }).pipe(
      map(user => {
        return user;
      })
    )
  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead    

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getUser(token) {
    return this.http.get(this.wpUrl + 'wp-json/mobileapi/v1/getProfile/?token=' + token).pipe(
      map(user => {
        return user;
      })
    )
  }

  //wp-json/pmpro/v1/cancelsubscription

  getData(endPoint) {
    return this.http.get(this.fullUrl + endPoint).pipe(
      map(data => {
        return data;
      })
    )
  }

  sendData(endPoint, data) {
    console.log(" URL ===============================" + this.fullUrl + endPoint, " DATA  ==== ", data);
    return this.http.post(this.fullUrl + endPoint, data).pipe(
      map(data => {
        return data;
      })
    )

  }

  get_page_content(token, page_name) {
    return this.http.get(this.wpUrl + 'wp-json/mobileapi/v1/get_page_content/?token=' + token + '&page_name=' + page_name).pipe(
      map(user => {
        return user;
      })
    )
  }

  addforum(feed, user) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/create_forum', {
      post_id: feed.post_id,
      post_title: feed.title,
      category: feed.category,
      skills: feed.skills,
      post_content: feed.description,
      token: user.token,
      how_many_people_looking_for: feed.how_many_people_looking_for,
      how_long_expect_this_job_to_last: feed.how_long_expect_this_job_to_last,
      contact_email: feed.contact_email,
      contact_mobile: feed.contact_mobile,
      job_post_expires: feed.job_post_expires,
      address: feed.address,
      latitude: feed.latitude,
      longitude: feed.longitude,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  addfeed(feed, user) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/create_feed', {
      post_id: feed.post_id,
      // category: feed.category,
      post_title: feed.title,
      post_content: feed.description,
      token: user.token,
      post_content_type: feed.post_content_type,
      post_attachment_url: feed.post_attachment_url,
      video_thumbnail: feed.video_thumbnail,
      tagged_users: feed.tagged_users,
      industry_sector: feed.industry_sector,
      business_subsector:feed.business_subsector,
      consumer_subsector: feed.consumer_subsector,
      energy_subsector: feed.energy_subsector,
      healthcare_subsector: feed.healthcare_subsector,
      industrials_subsector: feed.industrials_subsector,
      technology_subsector: feed.technology_subsector,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  add_edit_group(group, user) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/add_edit_group', {

      token: user.token,
      group_title: group.group_title,
      description: group.description,
      image_id: group.image_id,
      attachment_url: group.attachment_url,
      group_id: group.group_id,
      uid: group.uid,

    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  deletePost(token, post_id) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/delete_post', {
      token: token,
      post_id: post_id,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  get_faqs(page = 1, user_id, userToken: any = '', listType: any = 'allpost', mypost: number = 0, byid: number = 0, post_title: any = '', postPer: any = 10): Observable<any[]> {
    let options = {
      observe: "response" as 'body',
      params: {
        per_page: postPer,
        page: '' + page
      }
    };
    let post_title_url = post_title ? ("&search=" + post_title) : "";
    return this.http.get<any[]>(this.url + 'faq?_embed&token=' + userToken + "&listType=" + listType + "&user_id=" + user_id + "&mypost=" + mypost + "&byid=" + byid + post_title_url, options).pipe(
      map(resp => {
        this.pages = resp['headers'].get('x-wp-totalpages');
        this.totalPosts = resp['headers'].get('x-wp-total');

        let data = resp['body'];

        for (let post of data) {
          post.media_url = post['media_url'];
        }
        return data;
      })
    )
  }

  getForumsFeed(page = 1, user_id, userToken: any = '', listType: any = 'allpost', byid: number = 0, post_title: any = '', postPer: any = 10, cat_id): Observable<any[]> {
    let options = {
      observe: "response" as 'body',
      params: {
        per_page: postPer,
        page: '' + page
      }
    };
    let post_title_url = post_title ? ("&search=" + post_title) : "";
    return this.http.get<any[]>(this.url + 'forum?_embed&token=' + userToken + "&listType=" + listType + "&user_id=" + user_id + "&byid=" + byid + post_title_url + '&cat_id=' + cat_id, options).pipe(
      map(resp => {
        this.pages = resp['headers'].get('x-wp-totalpages');
        this.totalPosts = resp['headers'].get('x-wp-total');

        let data = resp['body'];

        for (let post of data) {
          post.media_url = post['media_url'];
        }
        return data;
      })
    )
  }

  getActivityFeed(page = 1, user_id, userToken: any = '', listType: any = 'allpost', mypost: number = 0, byid: number = 0, post_title: any = '', postPer: any = 10, cat_id): Observable<any[]> {
    let options = {
      observe: "response" as 'body',
      params: {
        per_page: postPer,
        page: '' + page
      }
    };
    let post_title_url = post_title ? ("&search=" + post_title) : "";
    return this.http.get<any[]>(this.url + 'activity?_embed&token=' + userToken + "&listType=" + listType + "&user_id=" + user_id + "&mypost=" + mypost + "&byid=" + byid + post_title_url + '&cat_id=' + cat_id, options).pipe(
      map(resp => {
        this.pages = resp['headers'].get('x-wp-totalpages');
        this.totalPosts = resp['headers'].get('x-wp-total');

        let data = resp['body'];

        for (let post of data) {
          post.media_url = post['media_url'];
        }
        return data;
      })
    )
  }

  replyComment(post_id, parent_cid, post_comment, videoThumbnail, comment_attachment_url, post_content_type, user) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/reply_comment', {
      token: user.token,
      post_id: post_id,
      parent_cid: parent_cid,
      post_comment: post_comment,
      video_thumbnail: videoThumbnail,
      comment_attachment_url: comment_attachment_url,
      post_content_type: post_content_type
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  deleteComment(comment_id, user) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/delete_comment', {
      token: user.token,
      comment_id: comment_id,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  get_forum_categories(token) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_forum_categories', {
      token: token
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  get_social_categories(token, taxonomy) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_social_categories', {
      token: token,
      taxonomy: taxonomy
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  get_forum_details(post_id, token) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_forum_details', {
      post_id: post_id,
      token: token
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  get_post_details(post_id, token) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_post_details', {
      post_id: post_id,
      token: token
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  get_comment_details(comment_id, token) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_comment_details', {
      comment_id: comment_id,
      token: token
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  getCommentChilds(commentID, token) {
    return this.http.get(this.wpUrl + 'wp-json/mobileapi/v1/get_comment_childs/?comment_id=' + commentID + '&token=' + token).pipe(
      map(user => {
        return user;
      })
    )
  }

  update_comment(comment) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/update_comment', comment).pipe(
      map(user => {
        return user;
      })
    )
  }

  likeDislike(id, type, user) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/like_dislike', {
      token: user.token,
      id: id,
      type: type
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  getCurrentUserInfo(userToken) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/getCurrentUserInfo", {
      token: userToken
    }).pipe(map(res => {
      return res;
    }));
  }

  getSecoondUserInfo(userToken, users_data) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/getSecoondUserInfo", {
      token: userToken,
      users_data: users_data
    }).pipe(map(res => {
      return res;
    }));
  }

  check_is_user_blocked(userToken, reciver_id) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/check_is_user_blocked", {
      token: userToken,
      reciver_id: reciver_id
    }).pipe(map(res => {
      return res;
    }));
  }

  get_featured_users(userToken) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_featured_users", {
      token: userToken,
    }).pipe(map(res => {
      return res;
    }));
  }

  get_all_users(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_all_users",sendData).pipe(map(res => {
      return res;
    }));
  }

  get_all_professionals(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_all_professionals", {
      token: sendData.token,
      serach_keyword: sendData.serach_keyword,
      selectedRole: sendData.selectedRole,
      page: sendData.page
    }).pipe(map(res => {
      return res;
    }));
  }

  get_category_list(userToken, serach_keyword) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_category_list", {
      token: userToken,
      serach_keyword: serach_keyword
    }).pipe(map(res => {
      return res;
    }));
  }

  get_other_user_details(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_other_user_details",sendData).pipe(map(res => {
      return res;
    }));
  }

  save_visitor_details(uid, token, page_name) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/save_visitor_details", {
      uid: uid,
      token: token,
      page_name: page_name
    });
  }

  get_my_notifications(token, page_no) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_my_notifications", {
      token: token,
      page: page_no
    });
  }

  getNotCount(token) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_my_not_count", {
      token: token,
    });
  }

  notisopened(token) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/notisopened", {
      token: token
    });
  }

  deleteNoti(token, nids) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/deleteNoti", {
      token: token,
      nids: nids,
    });
  }

  clear_all_notifications(token) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/clear_all_notifications", {
      token: token
    });
  }

  updateToken(userId, deviceID, deviceData, status) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/updateDeviceToken", {
      userid: userId,
      deviceID: deviceID,
      deviceData: deviceData,
      status: status
    }).pipe(map(res => {
      return res;
    }));
  }

  validateAuthToken(token) {
    let header = new HttpHeaders({ "Authorization": "Bearer " + token });
    return this.http.post(this.wpUrl + 'wp-json/jwt-auth/v1/token/validate?token=' + token,
      {}, { headers: header })
  }

  get_contacts_list(token, phone_contacts) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_contacts_list", {
      token: token,
      phone_contacts: phone_contacts
    });
  }

  get_followers(token, uid) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_followers', {
      token: token,
      uid: uid,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  get_following(token, uid) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_following', {
      token: token,
      uid: uid,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  user_follow_unfollow(token, leader_id, status, isNotify) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/user_follow_unfollow", {
      token: token,
      leader_id: leader_id,
      status: status,
      isNotify: isNotify
    });
  }
  user_request_deny(token, uid, status) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/user_request_deny", {
      token: token,
      uid: uid,
      status: status
    });
  }
  request_cancel(token, from_user_id) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/request_cancel", {
      token: token,
      from_user_id: from_user_id
    });
  }
  get_request_list(token) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_request_list", {
      token: token,
    });
  }
  request_accept(token, from_user_id, status) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/request_accept", {
      token: token,
      from_user_id: from_user_id,
      status: status,
    });
  }

  rate_user(token, uid, rate) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/rate_user", {
      token: token,
      uid: uid,
      rate: rate
    });
  }

  save_media(media_data, token) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/save_media", {
      token: token,
      post_id: media_data.post_id,
      title: media_data.title,
      media_type: media_data.media_type,
      media_url: media_data.media_url,
      video_thumbnail: media_data.video_thumbnail,
      audio_thumbnail: media_data.audio_thumbnail,
      description: media_data.description
    });
  }

  get_media_posts(token, uid, page) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_media_posts", {
      token: token,
      uid: uid,
      page: page
    });
  }

  get_portfolio_post_detail(token, postid) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_portfolio_post_detail", {
      token: token,
      postid: postid
    });
  }

  delete_media(token, id) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/delete_media', {
      token: token,
      id: id,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  sendContactMail(data) {
    console.log("SERVICES === ", data);
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/send_contact_mail', {
      name: data.name,
      email: data.email,
      phone: data.phone,
      description: data.description
    })
  }

  sendInvitationMail(data, token) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/send_invitation_mail', {
      token: token,
      email: data.email
    })
  }

  report_as_abuse(post) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/report_as_abuse', post).pipe(
      map(user => {
        return user;
      })
    )
  }

  user_block_unblock(token, blocked_userid, status) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/user_block_unblock", {
      token: token,
      blocked_userid: blocked_userid,
      status: status
    });
  }

  get_users_who_liked_post(post_id, token) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_users_who_liked_post', {
      post_id: post_id,
      token: token
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  sendPushNotification(token, secondUserID, msg, name, senderID) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/send_chat_notification", {
      token: token,
      secondUserID: secondUserID,
      msg: msg,
      name: name,
      senderID: senderID
    });
  }

  get_all_skills() {
    return this.http.get(this.wpUrl + 'wp-json/mobileapi/v1/get_all_skills').pipe(
      map(a => {
        return a;
      })
    )
  }

  async presentAlert(msg) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Alert',
      subHeader: '',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  get_my_followers_and_followings(token) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_my_followers_and_followings', {
      token: token
    }).pipe(
      map(user => {
        return user;
      })
    )
  }
  upload_image(data) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/upload_image', data).pipe(
      map(data => {
        return data;
      })
    )
  }
  upload_video(data) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/upload_video', data).pipe(
      map(data => {
        return data;
      })
    )
  }

  async dismissLoading() {
    if (this.isLoading) {
      this.isLoading = false;
      return await this.loadingCtrl.dismiss().then(() => console.log('loading dismissed'));
    }
  }

  async showLoader(msg: string = '', page_name: string = '') {
    if (msg == '') {
      msg = 'Please wait...';
    }
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: msg,
      spinner: 'circles',
      backdropDismiss: true,
      duration: 5000
    }).then(a => {
      a.present().then(() => {
        console.log('loading presented ' + page_name);
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort laoding'));
        }
      });
    });
  }

  get_connections(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_connections", {
      token: sendData.token,
      page: sendData.page,
      serach_keyword: sendData.serach_keyword,
    });
  }
  get_groups(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_groups", {
      token: sendData.token,
      page: sendData.page,
      serach_keyword: sendData.serach_keyword,
    });
  }
  get_group_details(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_group_details", {
      token: sendData.token,
      group_id: sendData.group_id,
    });
  }

  add_users_group(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/add_users_group", {
      token: sendData.token,
      group_id: sendData.group_id,
      author_id: sendData.author_id,
      selected_users: sendData.selected_users
    });
  }

  get_group_users(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_group_users", {
      token: sendData.token,
      group_id: sendData.group_id,
    });
  }

  delete_group(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/delete_group", {
      token: sendData.token,
      group_id: sendData.group_id,
    });
  }
  delete_user_group(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/delete_user_group", {
      token: sendData.token,
      group_id: sendData.group_id,
      uid: sendData.uid,
    });
  }
  add_schedule_call(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/add_schedule_call",sendData);
  }
  get_scheduled_calls(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_scheduled_calls",sendData);
  }
  get_requested_calls(token) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_requested_calls", {
      token: token,
    });
  }
  accept_requested_call(token, uid) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/accept_requested_call", {
      token: token,
      uid: uid,
    });
  }
  deny_requested_call(token, uid) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/deny_requested_call", {
      token: token,
      uid: uid,
    });
  }
  //for updated dashboard counter schedule calls
  requested_call_viewed(token) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/requested_call_viewed", {
      token: token,
    });
  }

  add_reminders(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/add_reminders",sendData);
  }
  get_reminders(token) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_reminders", {
      token: token,
    });
  }
  save_search(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/save_search",sendData);
  }

  get_saved_search(token) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_saved_search", {
      token: token,
    });
  }
  get_all_saved_search(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_all_saved_search", {
      token: sendData.token,
    });
  }
  delete_saved_search(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/delete_saved_search", {
      token: sendData.token,
      id: sendData.id,
    });
  }
  update_saved_search(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/update_saved_search", sendData);
  }
  add_edit_folder(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/create_update_folder", sendData);
  }
  get_folders(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_folder", sendData );
  }
  delete_folder(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/delete_folder", sendData );
  }
  save_to_folder(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/save_to_folder", sendData );
  }
  remove_to_folder(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/remove_to_folder", sendData );
  }
  get_folder_details(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_folder_details", sendData );
  }
  delete_folder_item(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/delete_folder_item", sendData );
  }

  get_about_page_content(page) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_about_page_content", page);
  }
  invite_user(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/invite_user", sendData);
  }

  // Professional pages APIs
  get_professional_pages(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_professional_pages", {
      token: sendData.token,
      page: sendData.page,
      serach_keyword: sendData.serach_keyword,
    });
  }

  get_professional_page_details(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_professional_page_details", {
      token: sendData.token,
      post_id: sendData.post_id
    });
  }

  create_professional_page(data, user) {
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/create_professional_page', {
      token: user.token,
      title: data.title,
      description: data.description,
      image_id: data.image_id,
      attachment_url: data.attachment_url,
      post_id: data.post_id,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }
  delete_professional_page(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/delete_professional_page", {
      token: sendData.token,
      post_id: sendData.post_id,
    });
  }

  get_professionals(token, list_type){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_professionals", {
      token: token,
      list_type: list_type
    });
  }

  get_my_sponsors_list(token, list_type) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_my_sponsors_list", {
      token: token,
      list_type: list_type
    });
  }

  accept_deny_request(token, author_id, status) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/accept_deny_request", {
      token: token,
      author_id: author_id,
      status: status,
    });
  }

  get_other_profile(senData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_other_profile",senData);
  }

  delete_reminder(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/delete_reminder",sendData);
  }
  
  share_folder(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/share_folder",sendData);
  }
  
  get_shared_folders(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_shared_folders",sendData);
  }

  add_call_feedback(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/add_call_feedback",sendData);
  }    
  
  touchLogin(user_id) {
    console.log("user_id === ", user_id);
    return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/touch_login', {
      user_id: user_id,
    })
  }

  active_device(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/active_device",sendData);
  }

  call_details(sendData) {
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/call_details",sendData);
  }
  get_personal_report(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_personal_report",sendData);
  }
  get_standard_report(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_standard_report",sendData);
  }
  get_statistical_report(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_statistical_report",sendData);
  }
  getUsers(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/getUsers",sendData);
  }
  get_public_post(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_public_post",sendData);
  }   
  get_sponsor_professionals(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_sponsor_professionals",sendData);
  }
  get_details_profesionals(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_details_profesionals",sendData);
  }
  get_activity_summary_report(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_activity_summary_report",sendData);
  }
  get_city_statistical_report(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_city_statistical_report",sendData);
  }
  get_state_statistical_report(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_state_statistical_report",sendData);
  }
  get_fund_size_statistical_report(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_fund_size_statistical_report",sendData);
  }

  get_portfolio_company_report(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_portfolio_company_report",sendData);
  }
  
  get_functional_areas_exe(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_functional_areas_exe",sendData);
  }
  get_pl_oversight_by_exe(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_pl_oversight_by_exe",sendData);
  }
  get_opportunities_by_exe(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_opportunities_by_exe",sendData);
  }
  get_city_by_company(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_city_by_company",sendData);
  }
  get_state_by_company(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_state_by_company",sendData);
  }
  get_ownership_by_company(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_ownership_by_company",sendData);
  }
  get_revenue_range_by_company(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_revenue_range_by_company",sendData);
  }
  google_authentication(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/google_authentication",sendData);
  }
  save_google_token(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/save_google_token",sendData);
  }
  get_naics(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_naics_field",sendData);
  }

  get_naics_parent_report(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_naics_parent_report",sendData);
  }
  
  get_naics_child_report(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_naics_child_report",sendData);
  }
  get_naics_report_executive(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_naics_report_executive",sendData);
  }
  get_op_industry(sendData){
    return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_op_industry",sendData);
  }
}
